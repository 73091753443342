@import "~src/style/variables.less";

.grid {
	table {
		@border: 1px solid gainsboro;

		max-width: none;
		min-width: 100%;
		margin-bottom: 0;
		border-collapse: separate;

		.display {
			display: inline;
		}

		.form-group > .control {
			display: block;
		}

		thead tr th {
			border-top: none;
			border-bottom: @border;
			border-right: @border;
			white-space: nowrap;
			background: #F0F0F0;
			font-weight: normal;

			a:hover, a:focus {
				text-decoration: none;
			}

			.glyphicon {
				opacity: 0.6;
				font-size: 80%;
				padding: 0.2em;
			}

			em.requried {
				display: none;
			}

			&:last-child {
				border-right: none;
			}

			&.col-size-default {
				width: auto;
			}

			&.col-size-extrasmall {
				min-width: @size-extrasmall;

				&.col-size-fixed {
					width: @size-extrasmall;
				}
			}

			&.col-size-small {
				min-width: @size-small;

				&.col-size-fixed {
					width: @size-small;
				}
			}

			&.col-size-medium {
				min-width: @size-medium;

				&.col-size-fixed {
					width: @size-medium;
				}
			}

			&.col-size-large {
				min-width: @size-large;

				&.col-size-fixed {
					width: @size-large;
				}
			}

			&.col-size-extralarge {
				min-width: @size-extralarge;

				&.col-size-fixed {
					width: @size-extralarge;
				}
			}

			&.col-size-huge {
				min-width: @size-huge;

				&.col-size-fixed {
					width: @size-huge;
				}
			}
		}

		tbody {
			tr td {
				border-top: none;
				border-bottom: @border;
				border-right: @border;
				padding: 0.2em 0.4em;
				vertical-align: top;

				.form-control.k-widget {
					&.k-datetimepicker > .k-picker-wrap {
						min-width: 11em;
					}

					&.k-datepicker > .k-picker-wrap {
						min-width: 6.5em;
					}

					&.k-timepicker > .k-picker-wrap {
						min-width: 5.5em;
					}
				}

				.display.multi-select {
					max-height: 6.5em;
					width: 100%;
				}

				&.error .control {
					> input, > .box {
						float: left;
						width: 80%;
					}

					> input {
						display: block;
					}

					> i {
						float: left;
						display: block;
						margin-right: 3px;
						margin-left: 3px;
					}
				}

				&.actions .btn-group {
					@margin: 5px;

					white-space: nowrap;
					margin-left: @margin;

					> .btn:first-child {
						margin-left: -@margin;
					}

					> .btn {
						float: inherit;
					}

					> .btn + .btn {
						margin-left: -@margin;
					}
				}

				&:last-child {
					border-right: none;
				}
			}

			th.edit, td.edit {
				min-width: 5em;
				overflow: hidden;
			}

			td.edit {
				position: relative;
			}

			td.edit div.control {
				white-space: nowrap;
			}

			th.edit {
				color: #999;
			}

			th.actions, td.actions {
				min-width: 3.5em;
				width: 3.5em;
				overflow: visible;
			}

			tr.error {
				background-color: #f6e9e9;
			}

			tr.removed td {
				background-color: #f2dede;
			}
		}
	}

	table.edit {
		th.required {
			font-weight: bold;

			em.required {
				display: inline;
			}
		}
	}

	table.select {
		th.actions, td.actions {
			width: 2em;
			text-align: center;
			white-space: nowrap;

			> .btn-danger {
				vertical-align: top;
			}
		}

		tbody {
			td.actions {
				background-color: #f0f0f0;
			}

			tr.selected {
				background-color: #fdfaed;
			}
		}
	}
}
