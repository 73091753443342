@import "~bootstrap/less/variables.less";
@import "~src/style/variables.less";

@na-color: #dedede;
@focused-border-color: #66AEE9;
@focused-background-color: mix(@input-bg, @focused-border-color, 90%);

form.form {
	display: flex;
	flex: 1;
	flex-direction: column;
	min-height: 20em;
	min-width: 20em;
	position: relative;
	visibility: hidden;

	> section.main {
		display: none;
	}

	.color-swatch {
		display: inline-block;
		width: 1em;
		height: 1em;
		margin-bottom: 0.8em;
		margin-right: 0.5em;
		border: thin solid black;
	}

	> header {
		flex-shrink: 0;
	}

	.button-group {
		input {
			line-height: 20px;
			width: 100%;
		}
	}

	.help-inline {
		margin-top: 6px;
		margin-right: 6px;
	}

	.expression-error {
		color: red;
	}

	.form-buttons {
		padding-bottom: 10px;
		text-align: right;
	}

	.form-footer {
		text-align: right;
	}

	&.form-horizontal {
		.control-label:after {
			content: ':';
			position: absolute;
			margin-left: 0.1em;
			bottom: 0;
			right: 0;
		}

		input[type="checkbox"] {
			margin-top: 6px;
		}

		input[type="radio"] {
			margin-top: 1px;
		}

		div.checkbox input[type="checkbox"] {
			margin-top: 2px;
		}
	}

	.form-control-static {
		min-height: 16px;

		&:empty:after {
			content: 'n/a';
			color: @na-color;
		}
	}

	.k-picker-wrap {
		&.k-state-hover,
		&.k-state-focused {
			background-color: transparent;
			background-image: none;
			box-shadow: none;
		}

		&.k-state-hover {
			border-color: #d0d0d0;
		}

		&.k-state-focused {
			border-color: @focused-border-color;
		}
	}

	.form-control:focus {
		background-color: @focused-background-color;
		box-shadow: none !important;
	}

	.select2-container-active {
		.select2-choice, .select2-choices {
			background-color: @focused-background-color;
		}
	}

	.select2-container-active.select2-dropdown-open {
		.select2-choice, .select2-choices {
			background-color: white;
		}
	}

	.form-control-static.email {
		&:empty:after {
			//zero-width non-joiner
			//email renders an empty link with a href of 'mailto:', if visible
			//content is used, a peculiar link appears which invokes the user's
			//mailto handler even though there is no recipient
			content: '\200c';
		}
	}

	.control-label {
		font-size: 11px;
		opacity: 0.7;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.required .control-label {
		font-weight: bold;
		opacity: 0.9;
	}

	.control-label.checkbox {
		display: inline-block;
	}

	fieldset {
		.alert.fieldset-description {
			margin-bottom: 10px;
		}
	}

	.field.edit {
		.controls {
			white-space: nowrap;
		}
	}

	em.required {
		color: red;
		display: none;
	}

	.field.required em.required {
		display: inline;
	}

	&.form-vertical {
		.checkbox input[type="checkbox"] {
			margin-top: 2px;
		}
	}

	pre.display {
		border: 0;
		background: transparent;
		font: inherit;
		white-space: pre-wrap;
		word-break: break-word;
	}

	.display {
		padding: 5px 0 0;
		display: inline-block;
		text-overflow: ellipsis;
		overflow: hidden;
		max-width: -webkit-fill-available;
		max-width: -moz-available;

		&.link-display {
			display: flex;
			flex: 1;
			padding: 0;
		}

		&.multi-select {
			max-height: 13em;
			padding-right: 1em;
			overflow: auto;

			> a {
				display: block;
			}
		}
	}

	.info-link {
		height: 1.8em;
		width: 1.8em;
		margin: 0.1em 0 0.1em 0.4em;
		padding: 0 0.7em 0 0.5em;
	}

	.edit-link {
		height: 1.8em;
		width: 2em;
		margin: 0.1em 0 0 0.4em;
		padding: 0;
	}

	.link-aside {
		display: inline-block;
		margin-left: 0.3em;
		padding: 0.2em 0.5em;
	}

	.null-value {
		color: @na-color;
	}

	.alert .btn-sm + strong {
		margin-left: 1em;
	}

	.display.removed {
		text-decoration: line-through;
		opacity: 0.4;
	}

	.has-fieldset .sub-tabs {
		margin-top: 20px;
	}

	.single-sub-tab .sub-tabs {
		.nav-tabs {
			display: none;
		}

		.tab-content {
			border: none;
			padding: 0;
		}
	}

	.update-mask, .save-mask {
		background: white;
		position: absolute;
		opacity: 0.6;
		z-index: 1000;
		background-image: url("../../../plugins/select2/spinner.gif");
		background-position: center center;
		background-repeat: no-repeat;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}

	.form-group {
		position: relative;
	}

	.box {
		display: table;
		width: 100%;
		box-sizing: border-box;
		table-layout: fixed;

		.select2-custom-container {
			display: flex;
			align-items: flex-start;

			.select2-dropdown-container {
				width: 100%;
				min-width: 50px;

				.select2-container[style] {
					display: block;
					width: 100% !important;
				}
			}

			.select2-button-container {
				margin-left: 0.3em;

				i {
					min-width: 10px;
				}
			}

			ul.select2-choices {
				overflow: auto;
				max-height: 200px;
			}
		}

		> a {
			margin-left: 5px;
		}
	}

	div.control .box {
		table-layout: auto !important;
	}

	.paginator {
		> ul {
			vertical-align: middle;
		}

		> .alert {
			padding: 5px 20px 8px 15px;
			margin-bottom: 0;
		}
	}

	select {
		option.option-group {
			color: black;
			font-size: 1.1em;
		}
	}

	.col-width-auto {
		width: auto;
	}

	.toggle-control {
		cursor: pointer;
	}

	div span.flag-option:not(:last-of-type):after {
		content: ", ";
	}

	&.application-directory section.main {
		padding: 0.5em;

		.k-widget.k-treeview .k-in {
			&.k-state-selected {
				background: #f9f0c4;

				.heading {
					color: #000000c4;
					font-weight: bold;
				}
			}

			span.fa {
				margin-right: 0;
				margin-left: 7px;
				vertical-align: middle;
			}
		}
	}
}

textarea.form-control {
	resize: vertical;
}

input.form-control {
	.text-left > & {
		text-align: left;
	}

	.text-right > & {
		text-align: right;
	}
}

span.status {
	height: 1.4em;
	width: 1.4em;
	margin-right: 0.1em;
	border-radius: 50%;
	display: inline-block;
}

.background-blue {
	background: #6b9fb9;
}

.background-red {
	background: #f77e7e;
}

.background-yellow {
	background: #f9dd54;
}

.background-green {
	background: #b8d09a;
}

.background-grey {
	background: #8f8f8f;
}

.background-purple {
	background: #b18eb1;
}
