@import "~src/style/mixins.less";

form.form {

	fieldset {
		margin: 0 .5em;

		> hr {
			border-top-color: @legend-border-color;
			margin-top: 0.5em;
		}

		legend {
			position: relative;

			label {
				display: inline;
				.transition-opacity();
			}

			.toggle-widget {
				cursor: pointer;
				height: 21px;
				background-color: #FFFFFF;
				color: #8C8C8C;
				user-select: none;
				font-size: 10px;

				.unfold {
					display: none;
				}

				.fold {
					top: 2px;
				}

				.glyphicon {
					margin: 0;
				}
			}
		}
	}

	fieldset.hide-content {
		legend {
			margin-bottom: 0;

			i.unfold {
				display: block;
			}

			i.fold {
				display: none;
			}

			label {
				opacity: 0.6;
			}
		}

		.content {
			display: none;
		}
	}

	.fieldset-row {
		display: flex;

		&.with-legends {
			fieldset > hr {
				margin-top: 35px;
			}
		}
	}
}
