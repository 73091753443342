.grid {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid gainsboro;
}
.grid > aside {
  flex-shrink: 0;
  border-bottom: 1px solid gainsboro;
}
.grid > aside.filter-search {
  flex: 0 0 auto;
  max-height: 40vh;
  overflow-y: auto;
}
.grid > aside.filter-search:not(.hide-content) {
  min-height: 8.7em;
}
.grid .grid-body {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 6.3em;
  position: relative;
}
.grid .grid-body .scroll-container {
  overflow: auto;
  width: 0;
  min-width: 100%;
  min-height: 5em;
}
.grid .grid-body .scroll-container .btn-group {
  position: initial;
}
.grid .grid-body .scroll-container .btn-group .dropdown-menu.above {
  margin: -2px 0;
}
.grid .grid-body .scroll-container .btn-group .dropdown-menu > li > a,
.grid .grid-body .scroll-container .btn-group .dropdown-menu > li > button {
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 16px;
  color: #333333;
  white-space: nowrap;
  background: none;
  border: none;
  display: block;
  box-sizing: border-box;
  width: 100%;
  text-align: left;
}
.grid .grid-body .scroll-container .btn-group .dropdown-menu > li > a:hover,
.grid .grid-body .scroll-container .btn-group .dropdown-menu > li > button:hover {
  background: #f5f5f5;
}
.grid .grid-body .scroll-container .btn-group .dropdown-menu > li > a:disabled,
.grid .grid-body .scroll-container .btn-group .dropdown-menu > li > button:disabled {
  opacity: 0.4;
}
.grid .grid-body .grid-buttons {
  padding: 0.4em;
  min-width: 0;
}
.grid .alert {
  border-radius: 0;
  border: none;
}
.grid .save-items {
  text-align: right;
  padding: 0.3em;
  background-color: #fbf9f9;
  border-bottom: 1px solid gainsboro;
}
.grid .select-panel {
  display: flex;
  align-items: center;
  padding: 0.7em;
  background-color: #fcf8e3;
  border: 1px solid #ddd;
  border-bottom: none;
}
.grid .select-panel > * {
  flex: 1;
  display: flex;
  margin-bottom: 0.3em;
}
.grid .select-panel .controls.near {
  margin-right: 0.5em;
}
.grid .select-panel .summary {
  justify-content: center;
  font-size: 1.1em;
  color: #333;
  opacity: 0.8;
  white-space: nowrap;
}
.grid .select-panel em {
  font-style: normal;
  font-weight: bold;
}
.grid .select-panel .controls.far {
  justify-content: flex-end;
}
.grid .select-panel aside button + button {
  margin-left: 0.3em;
}
.grid.with-filter .alert.empty {
  background: white;
  border-bottom: 1px solid #d9d9d9;
  color: #888;
}
.grid-edit,
.grid-select-button {
  text-align: right;
  position: absolute;
  right: 0;
  padding-top: 10px;
  padding-right: 5px;
}
.grid table {
  max-width: none;
  min-width: 100%;
  margin-bottom: 0;
  border-collapse: separate;
}
.grid table .display {
  display: inline;
}
.grid table .form-group > .control {
  display: block;
}
.grid table thead tr th {
  border-top: none;
  border-bottom: 1px solid gainsboro;
  border-right: 1px solid gainsboro;
  white-space: nowrap;
  background: #F0F0F0;
  font-weight: normal;
}
.grid table thead tr th a:hover,
.grid table thead tr th a:focus {
  text-decoration: none;
}
.grid table thead tr th .glyphicon {
  opacity: 0.6;
  font-size: 80%;
  padding: 0.2em;
}
.grid table thead tr th em.requried {
  display: none;
}
.grid table thead tr th:last-child {
  border-right: none;
}
.grid table thead tr th.col-size-default {
  width: auto;
}
.grid table thead tr th.col-size-extrasmall {
  min-width: 4em;
}
.grid table thead tr th.col-size-extrasmall.col-size-fixed {
  width: 4em;
}
.grid table thead tr th.col-size-small {
  min-width: 6em;
}
.grid table thead tr th.col-size-small.col-size-fixed {
  width: 6em;
}
.grid table thead tr th.col-size-medium {
  min-width: 11em;
}
.grid table thead tr th.col-size-medium.col-size-fixed {
  width: 11em;
}
.grid table thead tr th.col-size-large {
  min-width: 18em;
}
.grid table thead tr th.col-size-large.col-size-fixed {
  width: 18em;
}
.grid table thead tr th.col-size-extralarge {
  min-width: 24em;
}
.grid table thead tr th.col-size-extralarge.col-size-fixed {
  width: 24em;
}
.grid table thead tr th.col-size-huge {
  min-width: 32em;
}
.grid table thead tr th.col-size-huge.col-size-fixed {
  width: 32em;
}
.grid table tbody tr td {
  border-top: none;
  border-bottom: 1px solid gainsboro;
  border-right: 1px solid gainsboro;
  padding: 0.2em 0.4em;
  vertical-align: top;
}
.grid table tbody tr td .form-control.k-widget.k-datetimepicker > .k-picker-wrap {
  min-width: 11em;
}
.grid table tbody tr td .form-control.k-widget.k-datepicker > .k-picker-wrap {
  min-width: 6.5em;
}
.grid table tbody tr td .form-control.k-widget.k-timepicker > .k-picker-wrap {
  min-width: 5.5em;
}
.grid table tbody tr td .display.multi-select {
  max-height: 6.5em;
  width: 100%;
}
.grid table tbody tr td.error .control > input,
.grid table tbody tr td.error .control > .box {
  float: left;
  width: 80%;
}
.grid table tbody tr td.error .control > input {
  display: block;
}
.grid table tbody tr td.error .control > i {
  float: left;
  display: block;
  margin-right: 3px;
  margin-left: 3px;
}
.grid table tbody tr td.actions .btn-group {
  white-space: nowrap;
  margin-left: 5px;
}
.grid table tbody tr td.actions .btn-group > .btn:first-child {
  margin-left: -5px;
}
.grid table tbody tr td.actions .btn-group > .btn {
  float: inherit;
}
.grid table tbody tr td.actions .btn-group > .btn + .btn {
  margin-left: -5px;
}
.grid table tbody tr td:last-child {
  border-right: none;
}
.grid table tbody th.edit,
.grid table tbody td.edit {
  min-width: 5em;
  overflow: hidden;
}
.grid table tbody td.edit {
  position: relative;
}
.grid table tbody td.edit div.control {
  white-space: nowrap;
}
.grid table tbody th.edit {
  color: #999;
}
.grid table tbody th.actions,
.grid table tbody td.actions {
  min-width: 3.5em;
  width: 3.5em;
  overflow: visible;
}
.grid table tbody tr.error {
  background-color: #f6e9e9;
}
.grid table tbody tr.removed td {
  background-color: #f2dede;
}
.grid table.edit th.required {
  font-weight: bold;
}
.grid table.edit th.required em.required {
  display: inline;
}
.grid table.select th.actions,
.grid table.select td.actions {
  width: 2em;
  text-align: center;
  white-space: nowrap;
}
.grid table.select th.actions > .btn-danger,
.grid table.select td.actions > .btn-danger {
  vertical-align: top;
}
.grid table.select tbody td.actions {
  background-color: #f0f0f0;
}
.grid table.select tbody tr.selected {
  background-color: #fdfaed;
}
#main-content > form > section.main > .grid {
  border: none;
}
#main-content > form > section.main > .grid .grid-body table thead tr th:first-child,
#main-content > form > section.main > .grid .grid-body table tbody tr td:first-child {
  padding-left: 0.7em;
}
#main-content > form > section.main > .grid .grid-body table thead tr th:last-child,
#main-content > form > section.main > .grid .grid-body table tbody tr td:last-child {
  padding-right: 0.7em;
}
#main-content > form > section.main > .grid .grid-body .grid-buttons {
  padding: 0.4em 0.7em 1em;
}
#main-content > form > section.main > .grid .list-paginator {
  padding: 0.3em 0.7em;
}
#main-content > form > section.main > .grid .filter-search header {
  padding: 0.4em 0.6em;
}
.k-panel.list form,
.k-panel.report-query form {
  height: 100%;
  min-height: 0;
}
.k-panel .grid.with-filter .alert.empty,
.sub-tabs .grid.with-filter .alert.empty {
  border: none;
}
.k-panel.auto-height .grid,
.sub-tabs .grid {
  max-height: 75vh;
}
.grid-error {
  border-top: 1px solid #999;
  border-bottom: 1px solid #ccc;
}
.grid-error label {
  background: #FFFAD5;
  padding: 12px 4px;
  color: #666;
  font-size: 0.8em;
  display: block;
}
.grid-error span {
  font-style: italic;
  display: block;
  padding-top: 0.3em;
}
.filter-search {
  background-color: #f0f0f0;
}
.filter-search header {
  display: flex;
  align-items: center;
  padding: 0.4em;
}
.filter-search header .toggle-widget {
  padding: 0.4em 0.5em 0.2em;
  border-radius: 2px;
  border: 1px solid #ccc;
  margin-right: 0.5em;
  background-color: white;
  cursor: pointer;
}
.filter-search header .toggle-widget:hover {
  background-color: #e6e6e6;
}
.filter-search header label {
  opacity: 0.9;
  padding: 0.3em;
  margin: 0;
}
.filter-search header .controls {
  margin: 0 1em;
}
.filter-search header .filter-controls {
  display: flex;
  flex: 1;
}
.filter-search header .filter-controls .user-filter {
  margin: 0 0 0 auto;
}
.filter-search section.content {
  padding: 0.5em 0.7em 0;
  border-top: 2px groove rgba(255, 255, 255, 0.29);
}
.filter-search section.content > ul > li {
  float: left;
  padding-right: 2em;
  margin-bottom: 0;
  min-height: 55px;
  min-width: 18em;
}
.filter-search section.content label {
  color: #666;
}
.filter-search section.content .required em.required {
  display: inline;
}
.filter-search section.content .required label {
  font-weight: bold;
}
.filter-search.hide-content header .toggle-widget {
  background-color: rgba(255, 255, 255, 0.3);
}
.filter-search.hide-content header .toggle-widget:hover {
  background-color: rgba(230, 230, 230, 0.3);
}
.filter-search.hide-content header .controls {
  display: none;
}
.filter-search.hide-content section.content {
  display: none;
}
.list-paginator {
  display: flex;
  padding: 0.3em;
  background-color: #f0f0f0;
}
.list-paginator > * {
  flex: 1;
  display: flex;
  align-items: center;
}
.list-paginator ul.pagination {
  margin: 0;
  justify-content: flex-start;
}
.list-paginator .summary {
  justify-content: center;
}
.list-paginator .page-size {
  justify-content: flex-end;
}
.list-paginator .summary,
.list-paginator label {
  opacity: 0.8;
}
.list-paginator label {
  margin: 0;
}
.list-paginator label:after {
  content: ': ';
}
.list-paginator .btn.dropdown-toggle {
  background-color: #fff;
  border-color: #ddd;
  color: #555555;
}
.list-paginator .btn.dropdown-toggle .caret {
  margin-left: 2px;
}
.grid .paste-receiver {
  display: none;
  background: #fff9e7;
  padding: 0.2em 0.5em 0.3em;
  border: 1px solid #f9e9d6;
  vertical-align: top;
  margin: 0.4em -0.5em;
  border-radius: 3px;
}
.grid .focus-element {
  opacity: 0;
  width: 0;
  height: 0;
}
.grid .paste-receiver.active {
  display: inline-block;
}
.grid .empty .paste-receiver {
  margin: 0;
}
.gantt-container .k-gantt {
  border: 0;
}
.k-gantt .k-treelist tr.k-alt {
  background-color: #f0f0f0;
}
.k-gantt .k-treelist tr:hover {
  background-color: #e0e0e0;
}
.k-gantt .k-gantt-timeline table {
  min-width: 0;
}
.k-gantt td {
  vertical-align: middle;
}
.k-gantt td .removed {
  text-decoration: line-through;
  opacity: 0.4;
}
.k-gantt .k-gantt-content .k-gantt-timeline .k-grid-header-wrap table tr:nth-child(2) td {
  font-size: 10px;
  line-height: 20px;
}
.k-gantt .k-task-single,
.k-gantt .k-task-summary,
.k-gantt .k-task-summary-complete {
  min-width: 10px !important;
}
.k-gantt .k-task-milestone {
  width: 8px;
  height: 8px;
}
