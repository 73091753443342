.report {
	position: relative;
	height: 100%;
	display: flex;
	flex-direction: column;
	border: 1px solid gainsboro;

	> aside {
		flex-shrink: 0;
		border-bottom: 1px solid gainsboro;
    }

    > h3, > .report-header, > .report-footer {
        flex-shrink: 0;
    }

    .report-pivot {
        display: flex;
        flex: 1;
        flex-direction: row;
        min-height: 5em;
        overflow-y: hidden;

        .pivot-config-container {
            flex-shrink: 0;
            flex-grow: 0;
            border-style: solid;
            border-width: 1px 1px 1px 0;
            border-color: gainsboro;

            .pivot-config {
                border: 0;
            }
        }

        .pivot-grid-container {
            flex-grow: 1;
            border-style: solid;
            border-width: 1px 1px 1px 0;
            border-color: gainsboro;

            .pivot-grid {
                border: 0;
            }
        }
    }

    &.gantt .k-grid-content {
        .k-treelist-group td {
            font-weight: bold;
        }

        td {
            padding: 0.2em 0.4em;

            .btn  {
                line-height: 18px;
            }
        }
    }
}

.telerik-report {
	position: relative;
	flex: 1;

	.report-viewer {
		position: absolute;
		left: 5px;
		right: 5px;
		top: 5px;
		bottom: 5px;
		font-family: 'segoe ui', 'ms sans serif';
		overflow: hidden;

		.page-header {
			padding: 0;
			margin: 0;
			border: 0;
		}
	}
}

.k-panel {
    .pivot-config-container {
        display: none;
    }

    .k-pivot-setting .k-field-actions {
        display: none;
    }

    .telerik-report .report-viewer .trv-nav {
        #trv-main-menu-export-command {
            display: none;
        }

        .k-item > .k-link {
            padding: 0.5em;
        }
    }
}
