@import "~src/style/variables.less";

.form-control.k-widget {
	padding: 0;
	width: 100%;
	height: auto;
	line-height: @line-height-base;

	.k-picker-wrap {
		&:before {
			height: @input-height-base;
			padding-bottom: 0;
		}

		input.form-control.k-input {
			height: @input-height-base;
			padding: 0;
			font-size: @font-size-base;
			line-height: @line-height-base;
			text-indent: @padding-base-horizontal;
			color: @input-color;
		}

		&.k-state-disabled {
			padding: 0;
			opacity: initial;

			.k-input {
				border-radius: 3px;
			}

			.k-select {
				display: none;
			}
		}
	}
}
