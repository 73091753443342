.gantt-container {
    .k-gantt {
        border: 0;
    }
}

.k-gantt {
    .k-treelist tr {
        &.k-alt {
            background-color: #f0f0f0;
        }

        &:hover {
            background-color: #e0e0e0;
        }
    }

    .k-gantt-timeline {
        table {
            min-width: 0;
        }
    }

    td {
        vertical-align: middle;

        .removed {
            text-decoration: line-through;
            opacity: 0.4;
        }
    }

    .k-gantt-content .k-gantt-timeline .k-grid-header-wrap {
        table tr:nth-child(2) {
            td {
                font-size: 10px;
                line-height: 20px;
            }
        }
    }

    .k-task-single,
    .k-task-summary,
    .k-task-summary-complete {
        min-width: 10px !important;
    }

    .k-task-milestone {
        width: 8px;
        height: 8px;
    }
}
